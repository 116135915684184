var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isParticipantPortalField
        ? 'money-text-field is-participant-portal-field'
        : 'money-text-field is-general-field'},[(_vm.isParticipantPortalField && _vm.showHeader)?_c('div',{staticClass:"money-text-field-header-style"},[(_vm.required && !_vm.isAccountHolderField)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-text-field',{ref:"moneytextfield",staticClass:"money-text-field",class:{ large: _vm.large, 'text-right': _vm.textRight },attrs:{"rules":_vm.rules,"label":_vm.isParticipantPortalField
          ? ''
          : _vm.showLabel
          ? _vm.label + (_vm.required || _vm.hideOptionalText ? '' : ' (optional)')
          : '',"validate-on-blur":_vm.validateBlur,"hide-details":_vm.hideDetails,"prefix":_vm.prefix,"disabled":_vm.disabled,"clearable":_vm.clearable,"dense":_vm.dense,"color":_vm.$colors.penChecksTeal,"tabindex":_vm.tabindex,"outlined":_vm.isParticipantPortalField},on:{"focus":function($event){_vm.isFocused = true},"blur":_vm.inputBlurred,"keyup":_vm.keyUp,"change":function($event){return _vm.$emit('change', _vm.floatValue)}},model:{value:(_vm.formattedText),callback:function ($$v) {_vm.formattedText=$$v},expression:"formattedText"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }