<template>
  <v-expansion-panels
    class="pc-expansion-panels"
    :flat="flat"
    :accordion="accordion"
    :multiple="multiple"
    :focusable="focusable"
    :hover="hover"
    :disabled="disabled"
    :readonly="readonly"
  >
    <slot></slot>
  </v-expansion-panels>
</template>
<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: true,
    },
    accordion: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    focusable: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style></style>
