"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";
import Participant from "@/shared/lib/client-sdk/models/participant";
import OrderLineDetailDistributionService from "@/shared/lib/client-sdk/models/order-line-detail-distribution-service";
import OrderLineDetailTaxService from "@/shared/lib/client-sdk/models/order-line-detail-tax-service";
import OrderLineDetailRecurringService from "@/shared/lib/client-sdk/models/order-line-detail-recurring-service";
import PaymentInstruction from "@/shared/lib/client-sdk/models/payment-instruction";
import OrderLineFee from "@/shared/lib/client-sdk/models/order-line-fee";
import OrderLineDocument from "@/shared/lib/client-sdk/models/order-line-document";
import DistributionType from "@/shared/lib/client-sdk/models/distribution-type";
import DepositBatch from "@/shared/lib/client-sdk/models/deposit-batch";
import Plan from "@/shared/lib/client-sdk/models/plan";

class OrderLine extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "OrderLine";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.orderId +
      "/order-lines"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id
    );
  }

  getAdminPath() {
    return "/order-lines/" + this.id;
  }

  getClientPath() {
    return (
      "/" +
      this.masterAccountId +
      "/orders/" +
      this.orderId +
      "#participants:" +
      this.id
    );
  }

  get fullName() {
    var names = [];
    if (this.firstName && this.firstName.trim() != "")
      names.push(this.firstName.trim());
    if (this.lastName && this.lastName.trim() != "")
      names.push(this.lastName.trim());

    return names.join(" ");
  }

  get searchResultTitle() {
    return this.fullName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    var subtitle = [];

    if (this.participantUnmaskedTaxId) {
      var prefix =
        this.taxIdType !== "employerIdentificationNumber"
          ? "XXX-XX-"
          : "XX-XXX";

      subtitle.push(
        prefix +
          this.participantUnmaskedTaxId.substr(
            this.participantUnmaskedTaxId.length - 4
          )
      );
    }

    subtitle.push(" Plan: " + this.planDisplayName);

    if (this.grossDistributionAmount && this.grossDistributionAmount > 0)
      subtitle.push(
        "Gross Amount: " + Number.currencyString(this.grossDistributionAmount)
      );

    return subtitle;
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it loads it in.
  get relationships() {
    return {
      participant: Participant,
      orderLineDetailDistributionService: OrderLineDetailDistributionService,
      orderLineDetailTaxService: OrderLineDetailTaxService,
      orderLineDetailRecurringService: OrderLineDetailRecurringService,
      paymentInstructions: PaymentInstruction,
      orderLineFees: OrderLineFee,
      orderLineDocuments: OrderLineDocument,
      missingParticipantOriginalPlan: Plan,
      missingParticipantOriginalOrderLine: OrderLine,
      missingParticipantDepositBatch: DepositBatch,
    };
  }

  hasSentAtLeastOneNotice() {
    return (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.firstDistributionNoticeSentOn
    );
  }

  hasAtLeastOnePayment() {
    return this.totalAllPaymentsCount > 0;
  }

  canBeCompleted() {
    if (["waitingForReissue", "complete", "cancelled"].includes(this.status))
      return false;

    // If no money is required, let them complete the tax form orderline without processing it (TECH-5312: 1099 only order with fees waived)
    if (
      this.status == "submitted" &&
      this.totalFundsRequired == 0 &&
      this.orderServiceCode == "taxFormsOnly"
    )
      return true;

    if (this.assetTrackingLedgerBalance != 0) return false; // If the ledger balance is not zero, it cannot be completed

    // If it's a missing participant plan, allow it to be completed
    if (this.isDefaultMissingParticipantDistribution) {
      return true;
    }

    // If no money was associated, and no notices were sent, and no payments created, then you can't "complete" the order line because nothing has happened with it.
    if (
      this.totalFundsAssociated == 0 &&
      !this.hasSentAtLeastOneNotice() &&
      !this.hasAtLeastOnePayment()
    )
      return false;

    return true;
  }

  canBeDeleted() {
    if (this.deletedAt) return false; // Already deleted
    if (this.status == "inProgress" || this.status == "complete") return false; // Cannot delete an order line that is marked inProgress or 'complete'
    if (this.totalFundsAssociated != 0) return false; // If any money has been associated, we cannot delete the line
    if (this.assetTrackingLedgerBalance != 0) return false; // If the ledger balance is not zero, it cannot be deleted
    if (this.totalPaymentsCount > 0) return false; // If any payment records exist, we cannot delete the line
    if (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.firstDistributionNoticeSentOn
    )
      return false; // If a notice has been sent, we cannot delete the line

    return true;
  }

  canBeCancelled() {
    if (!this.canBeDeleted()) return false; // If we can't delete the line, then we can't cancel it either
    if (this.status == "cancelled") return false; // If it's already cancelled, we can't cancel it again

    return true;
  }

  initializeOrderLineDetailDistributionServiceIfMissing() {
    if (!this.orderLineDetailDistributionService) {
      this.orderLineDetailDistributionService =
        new OrderLineDetailDistributionService({
          orderLineId: this.id,
          distributionReasonCode: null,
          retirementAccountTypeCode: null,
          rothAccountOpenedInYear: null,
          rothBasisAmount: null,
          setRothBasisToZeroAcknowledgment: false,
          rothBalance: null,
          traditionalPreTaxAmount: null,
          traditionalPostTaxAmount: null,
          taxAuthorityStateCode: null,
          taxAuthorityLocalityName: null,
          localTaxPercentage: null,
          hasOutstandingLoan: false,
          loanIsDefaulting: false,
          loanOutstandingAmount: null,
          decedentName: null,
          decedentTaxId: null,
          decedentTaxIdType: null,
          decedentRelationship: null,
        });
    }
  }

  initializeOrderLineDetailRecurringServiceIfMissing() {
    if (!this.orderLineDetailRecurringService) {
      this.orderLineDetailRecurringService =
        new OrderLineDetailRecurringService({
          orderLineId: this.id,
          distributionReasonCode: null,
          traditionalPreTaxAmount: null,
          traditionalPostTaxAmount: null,
          taxFormCode: null,
          federalTaxAmountToWithhold: null,
          taxAuthorityStateCode: null,
          stateTaxAmountToWithhold: null,
          taxAuthorityLocalityName: null,
          localTaxAmountToWithhold: null,
          paymentFrequencyCode: null,
          targetSpecificDayOfMonth: null,
          targetPaymentMonth: null,
          firstPaymentMonth: null,
          finalPaymentMonth: null,
          sendPaymentAdvice: null,
          sendPaymentAdviceVia: null,
          annuityTypeCode: null,
          shouldUseBox2b: false,
          isWaitingForPlanAssets: true,
          IsPaymentCreationPaused: false,
        });
    }
  }

  initializeOrderLineDetailTaxServiceIfMissing() {
    if (!this.orderLineDetailTaxService) {
      this.orderLineDetailTaxService = new OrderLineDetailTaxService({
        orderLineId: this.id,
        taxYear: null,
        retirementAccountTypeCode: null,
        rothAccountOpenedInYear: null,
        distributionReasonCode: null,
        distributionTypeCode: null,
        optOutOfFederalTaxWitholding: null,
        taxAuthorityStateCode: null,
        optOutOfStateTaxWitholding: null,
        taxAuthorityLocalityName: null,
        optOutOfLocalTaxWitholding: null,
        grossDistributionAmount: null,
        taxableAmount: null,
        taxableAmountNotDeterminable: null,
        nonTaxableAmount: null,
        federalTaxWithheld: null,
        stateTaxWithheld: null,
        localTaxWithheld: null,
        employeeContributionsRothInsurancePremiums: null,
        netUnrealizedAppreciationInEmployerSecurities: null,
        distributionCode: null,
        hasDefaultedLoan: null,
        defaultedLoanAmount: null,
        defaultedLoanTaxableAmount: null,
        defaultedLoanTaxableAmountNotDeterminable: null,
        defaultedLoanNonTaxableAmount: null,
        defaultedLoanFederalTaxWithheld: null,
        defaultedLoanStateTaxWithheld: null,
        defaultedLoanLocalTaxWithheld: null,
        defaultedLoanEmployeeContributionsRothInsurancePremiums: null,
        defaultedLoanNetUnrealizedAppreciationInEmployerSecurities: null,
        defaultedLoanDistributionCode: null,
      });
    }
  }

  addEmptyPaymentInstruction() {
    if (!this.paymentInstructions || !$.isArray(this.paymentInstructions))
      this.paymentInstructions = [];

    this.paymentInstructions.push({
      distributionTypeCode: null,
      paymentTypedistributionAccessPin: null,
      payableTo: null,
      forBenefitOf: null,
      isDestinationAddressSameAsParticipant: null,
      destinationAttentionTo: null,
      destinationAddressLine1: null,
      destinationAddressLine2: null,
      destinationCity: null,
      destinationStateCode: null,
      destinationOtherRegion: null,
      destinationPostalCode: null,
      destinationCountryCode: null,
      dateOnCheck: null,
      electronicPaymentBankName: null,
      electronicPaymentAccountType: null,
      electronicPaymentRoutingNumber: null,
      electronicPaymentAccountNumber: null,
      electronicPaymentIsInternational: null,
      electronicPaymentInternationalCountryCode: null,
      additionalMemo: null,
      taxYear: null,
      grossUntaxableAmount: 0,
      grossTaxableAmount: 0,
      totalPayeeFeesOnUntaxableAmount: 0,
      totalPayeeFeesOnTaxableAmount: 0,
      totalFeesNotPaidByPayee: 0,
      totalFederalTaxAmount: 0,
      totalStateTaxAmount: 0,
      totalLocalTaxAmount: 0,
    });
  }

  getAssociations(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/associations";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getFeeDependencyData(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/fee-dependency-data";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getAgreements(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/agreements";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  addSupplementDocument(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/supplement-document";

    let formData = new FormData();
    formData.append("file", this.supplementDocument);

    ApiRequest.upload(requestUrl, formData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  deleteSupplementDocument(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/supplement-document";

    ApiRequest.send("DELETE", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          this.supplementDocumentStoredFileId = null;
          this.supplementDocument = null;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  get sumClientPaidFees() {
    return (
      this.totalRequestorFeesPaidBySubaccount +
      this.totalOtherFeesPaidByMasterAccount +
      this.totalOtherFeesPaidBySubaccount
    );
  }

  get totalFeesPaidBySubaccount() {
    return Math.addAmounts(
      this.totalRequestorFeesPaidBySubaccount,
      this.totalOtherFeesPaidBySubaccount
    );
  }

  get retirementAccountTypeCode() {
    if (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.retirementAccountTypeCode
    ) {
      return this.orderLineDetailDistributionService.retirementAccountTypeCode;
    }

    if (
      this.orderLineDetailTaxService &&
      this.orderLineDetailTaxService.retirementAccountTypeCode
    ) {
      return this.orderLineDetailTaxService.retirementAccountTypeCode;
    }

    return "traditional";
  }

  get distributionReasonCode() {
    if (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.distributionReasonCode
    ) {
      return this.orderLineDetailDistributionService.distributionReasonCode;
    }

    if (
      this.orderLineDetailTaxService &&
      this.orderLineDetailTaxService.distributionReasonCode
    ) {
      return this.orderLineDetailTaxService.distributionReasonCode;
    }

    return null;
  }

  get grossTaxableAmount() {
    if (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.grossTaxableAmount
    ) {
      return this.orderLineDetailDistributionService.grossTaxableAmount;
    }

    if (
      this.orderLineDetailRecurringService &&
      this.orderLineDetailRecurringService.traditionalPreTaxAmount
    ) {
      return this.orderLineDetailRecurringService.traditionalPreTaxAmount;
    }

    if (
      this.orderLineDetailTaxService &&
      this.orderLineDetailTaxService.taxableAmount
    ) {
      return this.orderLineDetailTaxService.taxableAmount;
    }

    return null;
  }

  get grossUntaxableAmount() {
    if (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.grossUntaxableAmount
    ) {
      return this.orderLineDetailDistributionService.grossUntaxableAmount;
    }

    if (
      this.orderLineDetailRecurringService &&
      this.orderLineDetailRecurringService.traditionalPostTaxAmount
    ) {
      return this.orderLineDetailRecurringService.traditionalPostTaxAmount;
    }

    return null;
  }

  get sumPaymentInstructionsGrossUntaxableAmount() {
    var sum = 0;

    if (this.paymentInstructions) {
      for (var i = 0; i < this.paymentInstructions.length; i++) {
        var amount = this.paymentInstructions[i].grossUntaxableAmount;
        amount = amount && amount >= 0 ? amount : 0;

        sum = Math.addAmounts(sum, amount);
      }
    }

    return sum;
  }

  get sumPaymentInstructionsGrossTaxableAmount() {
    var sum = 0;

    if (this.paymentInstructions) {
      for (var i = 0; i < this.paymentInstructions.length; i++) {
        var amount = this.paymentInstructions[i].grossTaxableAmount;
        amount = amount && amount >= 0 ? amount : 0;

        sum = Math.addAmounts(sum, amount);
      }
    }

    return sum;
  }

  get sumPaymentInstructionsGrossPaymentAmount() {
    return Math.addAmounts(
      this.sumPaymentInstructionsGrossUntaxableAmount,
      this.sumPaymentInstructionsGrossTaxableAmount
    );
  }

  // Total untaxable amount that still needs to be created for payment instructions
  get paymentInstructionsGrossUntaxableBalance() {
    return Math.subtractAmounts(
      this.grossUntaxableAmount,
      this.sumPaymentInstructionsGrossUntaxableAmount
    );
  }

  // Total taxable amount that still needs to be created for payment instructions
  get paymentInstructionsGrossTaxableBalance() {
    return Math.subtractAmounts(
      this.grossTaxableAmount,
      this.sumPaymentInstructionsGrossTaxableAmount
    );
  }

  // Total amount that still needs to be created for payment instructions
  get paymentInstructionsGrossBalance() {
    return Math.subtractAmounts(
      this.grossDistributionAmount,
      this.sumPaymentInstructionsGrossPaymentAmount
    );
  }

  get loanAmount() {
    if (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.loanOutstandingAmount
    ) {
      return this.orderLineDetailDistributionService.loanOutstandingAmount;
    }

    if (
      this.orderLineDetailTaxService &&
      this.orderLineDetailTaxService.defaultedLoanAmount
    ) {
      return this.orderLineDetailTaxService.defaultedLoanAmount;
    }

    return null;
  }

  get taxAuthorityStateCode() {
    if (
      this.orderLineDetailDistributionService &&
      this.orderLineDetailDistributionService.taxAuthorityStateCode
    ) {
      return this.orderLineDetailDistributionService.taxAuthorityStateCode;
    }

    if (
      this.orderLineDetailTaxService &&
      this.orderLineDetailTaxService.taxAuthorityStateCode
    ) {
      return this.orderLineDetailTaxService.taxAuthorityStateCode;
    }

    return null;
  }

  allowsMultiplePaymentInstructions(availableDistributionTypes) {
    var allowsMultiplePaymentInstructions = this.isDistributionFromTraditional;
    var allowsRolloverDistribution = this.allowsRolloverDistribution(
      availableDistributionTypes
    );
    var allowsCashLumpSumDistribution = this.allowsCashLumpSumDistribution(
      availableDistributionTypes
    );

    return (
      allowsMultiplePaymentInstructions &&
      allowsRolloverDistribution &&
      allowsCashLumpSumDistribution
    );
  }

  allowsRolloverDistribution(availableDistributionTypes) {
    return (
      DistributionType.hasRolloverDistributionType(
        availableDistributionTypes
      ) && this.orderServiceCode != "uncashedChecksNonQualified"
    );
  }

  allowsCashLumpSumDistribution(availableDistributionTypes) {
    return DistributionType.hasCashLumpSumDistributionType(
      availableDistributionTypes
    );
  }

  get isDistributionFromTraditional() {
    return this.retirementAccountTypeCode == "traditional";
  }

  get isDistributionFromRoth() {
    return this.retirementAccountTypeCode == "roth";
  }

  get isDefaultMissingParticipantDistribution() {
    if (this.orderLineDetailDistributionService) {
      return this.orderLineDetailDistributionService
        .isDefaultMissingParticipantDistribution;
    }

    return false;
  }

  get hasDefaultingLoan() {
    if (this.orderLineDetailDistributionService) {
      return (
        this.orderLineDetailDistributionService.hasOutstandingLoan &&
        this.orderLineDetailDistributionService.loanIsDefaulting
      );
    }

    return false;
  }

  get hasNonDefaultingLoan() {
    if (this.orderLineDetailDistributionService) {
      return (
        this.orderLineDetailDistributionService.hasOutstandingLoan &&
        !this.orderLineDetailDistributionService.loanIsDefaulting
      );
    }

    return false;
  }

  get canDisplay1099Suppression() {
    if (this.orderServiceCode) {
      return ["taxFormsOnly", "taxPaymentsAndForms"].includes(
        this.orderServiceCode
      );
    }
    return false;
  }

  static unlockDistribution(pin, orderLineId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      orderLineId +
      "/unlock-distribution";

    var requestBody = {
      pin,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  static changeDistributionWaitingPeriod(
    participantElectedToKeepWaitingPeriod,
    keepWaitingPeriodSetByStaffMember,
    salesforceCaseId,
    orderLineId,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      orderLineId +
      "/waiting-period";

    var requestBody = {
      participantElectedToKeepWaitingPeriod,
      keepWaitingPeriodSetByStaffMember,
      salesforceCaseId,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  static exportMissingParticipantBalanceUpdates(
    selectedOrderLineIds,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/export-missing-participant-balance-updates";

    var requestData = {
      selectedOrderLineIds: selectedOrderLineIds,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  static importOrderLineBalanceUpdates(file, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/import-missing-participant-balance-updates";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, OrderLine).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static importOrderLineClientIdentifierUpdates(file, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/import-client-identifier-updates";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, OrderLine).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static importOrderLineFullBalanceUpdates(file, planId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/import-full-balance-updates";

    let formData = new FormData();
    formData.append("file", file);
    formData.append("planId", planId);

    ApiRequest.upload(requestUrl, formData, OrderLine).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static importOrderLineNrurbUpdates(file, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/import-nrurb-updates";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, OrderLine).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static exportMissingParticipantNewRollovers(selectedOrderLineIds, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/export-missing-participant-new-rollovers";

    var requestData = {
      selectedOrderLineIds: selectedOrderLineIds,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  static mergeDuplicateOrderLines(selectedOrderLineIds, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/merge-duplicates";

    var requestData = {
      selectedOrderLineIds: selectedOrderLineIds,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  static performNonResponsiveParticipantActions(
    nonResponsiveParticipantAction,
    selectedOrderLineIds,
    forceAction,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/non-responsive-participant-actions";

    var requestData = {
      nonResponsiveParticipantAction: nonResponsiveParticipantAction,
      selectedOrderLineIds: selectedOrderLineIds,
      forceAction: forceAction,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  updateMissingParticipantPaymentStatus(status, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      `/private/order-lines/${this.id}/missing-participant-payment-status`;

    var requestData = {
      substatus: status,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  resetLockedPin(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/" +
      this.id +
      "/reset-locked-pin";

    ApiRequest.send("POST", requestUrl, {}).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  updateResponsePeriodEndDate(respnsePeriodEndDate, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/nonresponsive-participant-info";

    var requestData = {
      becomesNonResponsiveParticipantAtEndOfDayOn: respnsePeriodEndDate,
    };

    ApiRequest.send("PATCH", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  createPaymentInstructions(
    participantPersonalInformationChangeRequest,
    participantAuthorizationAgreement,
    participantTaxNoticeAgreement,
    participantNextlevelIraAgreement,
    participantElectedToKeepWaitingPeriod,
    sendParticipantConfirmationCode,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/payment-instructions";

    var requestBody = {
      participant: this.participant,
      participantPersonalInformationChangeRequest:
        participantPersonalInformationChangeRequest,
      participantAuthorizationAgreement: participantAuthorizationAgreement,
      participantTaxNoticeAgreement: participantTaxNoticeAgreement,
      participantNextlevelIraAgreement: participantNextlevelIraAgreement,
      participantElectedToKeepWaitingPeriod:
        participantElectedToKeepWaitingPeriod,
      sendParticipantConfirmationCode: sendParticipantConfirmationCode,
      paymentInstructions: this.paymentInstructions,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(function (
      response
    ) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  verifyElectronicPayment(
    paymentMethodCode,
    routingNumber,
    accountNumber,
    isAdminOrClient,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/verify-electronic-payment";

    var requestData = {
      paymentMethodCode,
      routingNumber,
      accountNumber,
      isAdminOrClient,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(function (
      response
    ) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  authenticateElectronicPayment(options, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/authenticate-electronic-payment";

    var requestData = {
      paymentMethodCode: options.paymentMethodCode,
      routingNumber: options.routingNumber,
      accountNumber: options.accountNumber,
      participantFirstName: options.participantFirstName || null,
      participantLastName: options.participantLastName || null,
      isAdminOrClient: options.isAdminOrClient,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(function (
      response
    ) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  getDistributionNoticeTemplates(
    isInitialNotice,
    isOrderLineNotice,
    isMissingNoticeQueue,
    isAdditionalNotice,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/distribution-notice-templates?isInitialNotice=" +
      isInitialNotice +
      "&isOrderLineNotice=" +
      isOrderLineNotice +
      "&isMissingNoticeQueue=" +
      isMissingNoticeQueue +
      "&isAdditionalNotice=" +
      isAdditionalNotice;

    ApiRequest.send("GET", requestUrl).onComplete(function (response) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  addDistributionNotice(
    documentTemplateId,
    sendMethod,
    becomesNonResponsiveParticipantOn,
    isOrderLineNotice,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/" +
      this.id +
      "/distribution-notice";

    var requestData = {
      documentTemplateId: documentTemplateId,
      sendMethod: sendMethod,
      becomesNonResponsiveParticipantAtEndOfDayOn:
        becomesNonResponsiveParticipantOn,
      isOrderLineNotice: isOrderLineNotice,
    };

    ApiRequest.send("POST", requestUrl, requestData, OrderLine).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) {
            callback(response.result, null);
          }
        } else {
          if ($.isFunction(callback)) {
            callback(null, response);
          }
        }
      }
    );
  }

  static getOrderLinesWithMissingDistributionNoticeCount(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/missing-distribution-notice-count";

    ApiRequest.send("GET", requestUrl, null, OrderLine).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  calculateRetirementDistributionAmounts(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-line-calculator/" +
      this.id;

    var participant = this.participant;
    var distributionDetail = this.orderLineDetailDistributionService;
    var taxDetail = this.orderLineDetailTaxService;

    var requestData = {
      payeeAge: participant.participantAge, //is int on the request should be decimal or float
      isPayeeNonResidentAlien: participant.isNonResidentAlien,
      paymentInstructions: [],
      userExplicitlySetFederalTax: false,
      userExplicitlySetStateTax: false,
    };

    // DISTRIBUTION DETAIL request

    if (distributionDetail) {
      requestData.reportTaxesToStateCode = participant.stateCode;
      requestData.distributionReasonCode =
        distributionDetail.distributionReasonCode;
      requestData.calculateFeesAutomatically = true;
      requestData.retirementAccount = {
        accountType: distributionDetail.retirementAccountTypeCode,
      };

      if (distributionDetail.retirementAccountTypeCode == "traditional") {
        requestData.retirementAccount.traditionalPreTaxAmount =
          distributionDetail.traditionalPreTaxAmount
            ? distributionDetail.traditionalPreTaxAmount
            : 0;
        requestData.retirementAccount.traditionalPostTaxAmount =
          distributionDetail.traditionalPostTaxAmount
            ? distributionDetail.traditionalPostTaxAmount
            : 0;
        requestData.retirementAccount.defaultingLoanAmount =
          distributionDetail.loanIsDefaulting
            ? distributionDetail.loanOutstandingAmount
              ? distributionDetail.loanOutstandingAmount
              : 0
            : 0;
      } else if (distributionDetail.retirementAccountTypeCode == "roth") {
        requestData.retirementAccount.rothBalance =
          distributionDetail.rothBalance ? distributionDetail.rothBalance : 0;
        requestData.retirementAccount.rothBasis =
          distributionDetail.rothBasisAmount
            ? distributionDetail.rothBasisAmount
            : 0;
        requestData.retirementAccount.rothAccountOpenedInYear =
          distributionDetail.rothAccountOpenedInYear
            ? distributionDetail.rothAccountOpenedInYear
            : 0;
      }

      requestData.feeCalculationData = {
        subaccountId: this.subaccountId,
        planId: this.planId,
        orderId: this.orderId,
        serviceFeesPaidBy: this.serviceFeesPaidByCode,
        paymentMethodFeesPaidBy: this.paymentMethodFeesPaidByCode,
        requestorFeesPaidBy: this.requestorFeesPaidByCode,
        orderServiceCode: this.orderServiceCode,
        distributionReasonCode: distributionDetail.distributionReasonCode,
        recurringPaymentFrequency: null,
      };

      // var grossDistributionTotal = 0;

      for (let paymentInstruction of this.paymentInstructions) {
        let requestedFederalTaxAmount = null;
        let requestedFederalTaxPercentage = null;
        let requestedStateTaxAmount = null;
        let requestedStateTaxPercentage = null;

        if (paymentInstruction.federalTaxes) {
          if (paymentInstruction.federalTaxes.requestedTaxAmount) {
            requestedFederalTaxAmount =
              paymentInstruction.federalTaxes.requestedTaxAmount;
            requestData.userExplicitlySetFederalTax = true;
          } else {
            requestedFederalTaxAmount = null;
          }
          if (paymentInstruction.federalTaxes.requestedTaxPercentage) {
            requestedFederalTaxPercentage =
              paymentInstruction.federalTaxes.requestedTaxPercentage;
            requestData.userExplicitlySetFederalTax = true;
          } else {
            requestedFederalTaxPercentage = null;
          }
        }

        if (paymentInstruction.stateTaxes) {
          if (paymentInstruction.stateTaxes.requestedTaxAmount) {
            requestedStateTaxAmount =
              paymentInstruction.stateTaxes.requestedTaxAmount;
            requestData.userExplicitlySetStateTax = true;
          } else {
            requestedStateTaxAmount = null;
          }
          if (paymentInstruction.stateTaxes.requestedTaxPercentage) {
            requestedStateTaxPercentage =
              paymentInstruction.stateTaxes.requestedTaxPercentage;
            requestData.userExplicitlySetStateTax = true;
          } else {
            requestedStateTaxPercentage = null;
          }
        }

        var paymentInstructionRequest = {
          paymentInstructionIdentifier:
            paymentInstruction.paymentInstructionIdentifier,
          distributionTypeCode: paymentInstruction.distributionTypeCode,
          paymentMethodCode: paymentInstruction.paymentMethodCode,
          requestedFederalTaxAmount: requestedFederalTaxAmount,
          requestedFederalTaxPercentage: requestedFederalTaxPercentage,
          requestedStateTaxAmount: requestedStateTaxAmount,
          requestedStateTaxPercentage: requestedStateTaxPercentage,
        };

        if (distributionDetail.retirementAccountTypeCode == "traditional") {
          paymentInstructionRequest.traditionalAmount =
            paymentInstruction.grossDistributionAmount;
          paymentInstructionRequest.defaultingLoanAmount =
            distributionDetail.loanIsDefaulting
              ? distributionDetail.loanOutstandingAmount
              : null;
        }

        requestData.paymentInstructions.push(paymentInstructionRequest);
      }
    }

    // TAX DETAIL request

    if (taxDetail) {
      requestData.reportTaxesToStateCode = taxDetail.taxAuthorityStateCode;
      requestData.distributionReasonCode = taxDetail.distributionReasonCode;
      requestData.retirementAccount = {
        accountType: taxDetail.retirementAccountTypeCode,
      };

      if (taxDetail.retirementAccountTypeCode == "traditional") {
        requestData.retirementAccount.traditionalPreTaxAmount = 0;
        requestData.retirementAccount.traditionalPostTaxAmount = 0;
      } else if (taxDetail.retirementAccountTypeCode == "roth") {
        requestData.retirementAccount.rothBalance = 0;
        requestData.retirementAccount.rothBasis = 0;
        requestData.retirementAccount.rothAccountOpenedInYear =
          taxDetail.rothAccountOpenedInYear;
      }

      let paymentInstruction = {
        paymentInstructionIdentifier: "1",
        distributionTypeCode: taxDetail.distributionTypeCode,
        totalFeesPaidByPayee: 0,
      };

      if (taxDetail.retirementAccountTypeCode == "traditional") {
        paymentInstruction.traditionalPreTaxAmount = 0;
        paymentInstruction.traditionalPostTaxAmount = 0;
      }

      requestData.paymentInstructions.push(paymentInstruction);
    }

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          for (let paymentInstruction of this.paymentInstructions) {
            var paymentInstructionResult = null;

            for (var result of response.result.paymentInstructions) {
              if (
                paymentInstruction.paymentInstructionIdentifier ==
                result.paymentInstructionIdentifier
              ) {
                paymentInstructionResult = result;
                break;
              }
            }

            paymentInstruction.grossDistributionAmount =
              paymentInstructionResult.grossDistributionAmount;
            paymentInstruction.grossTaxableAmount =
              paymentInstructionResult.grossTaxableDistributionAmount;
            paymentInstruction.grossUntaxableAmount =
              paymentInstructionResult.grossUntaxableDistributionAmount;
            paymentInstruction.additionalDefaultingLoanTaxableAmount =
              paymentInstructionResult.additionalTaxAssessedAmountForDefaultingLoan;
            paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees =
              paymentInstructionResult.taxableAmountIncludingDefaultingLoanLessDeductedFees;
            paymentInstruction.netDistributionAmount =
              paymentInstructionResult.netDistributionAmount;

            paymentInstruction.totalFeesFromTaxableAmountPaidByPayee =
              paymentInstructionResult.deductedFeesFromTaxableAmount;
            paymentInstruction.totalFeesFromUntaxableAmountPaidByPayee =
              paymentInstructionResult.deductedFeesFromUntaxableAmount;
            paymentInstruction.totalFeesPaidByPayee = Math.addAmounts(
              paymentInstruction.totalFeesFromTaxableAmountPaidByPayee,
              paymentInstruction.totalFeesFromUntaxableAmountPaidByPayee
            );

            if (
              paymentInstruction.federalTaxes &&
              paymentInstructionResult.federalTaxes
            ) {
              paymentInstruction.federalTaxes.taxAuthority =
                paymentInstructionResult.federalTaxes.taxAuthority;
              paymentInstruction.federalTaxes.taxFormCode =
                paymentInstructionResult.federalTaxes.taxFormCode;
              paymentInstruction.federalTaxes.assessedTaxPercentage =
                paymentInstructionResult.federalTaxes.assessedTaxPercentage;
              paymentInstruction.federalTaxes.assessedTaxMinimumWitholdingAmount =
                paymentInstructionResult.federalTaxes.assessedTaxMinimumWitholdingAmount;
              paymentInstruction.federalTaxes.assessedTaxAllowsVoluntaryOptOut =
                paymentInstructionResult.federalTaxes.assessedTaxAllowsVoluntaryOptOut;
              // only overwrite actual tax amount when not already set or when actual tax amount
              // is smaller than assessed tax minimum withholding amount or when the participant
              // requested a percentage/amount that results in an amou t greater than the assessed
              // tax minimum withholding amount.
              if (paymentInstructionResult.userExplicitlySetFederalTax) {
                if (paymentInstruction.federalTaxes.requestedTaxPercentage) {
                  paymentInstruction.federalTaxes.actualTaxAmount =
                    paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees *
                    paymentInstruction.federalTaxes.requestedTaxPercentage;
                } else {
                  paymentInstruction.federalTaxes.actualTaxAmount =
                    paymentInstruction.federalTaxes.requestedTaxAmount;
                }
              } else {
                paymentInstruction.federalTaxes.actualTaxAmount =
                  paymentInstruction.federalTaxes.actualTaxAmount != null &&
                  (paymentInstruction.federalTaxes.isVoluntarilyOptedOut ||
                    paymentInstruction.federalTaxes.actualTaxAmount >
                      paymentInstructionResult.federalTaxes
                        .assessedTaxMinimumWitholdingAmount)
                    ? paymentInstruction.federalTaxes.actualTaxAmount
                    : paymentInstructionResult.federalTaxes
                        .assessedTaxMinimumWitholdingAmount;
              }
              paymentInstruction.federalTaxes.actualTaxPercentage =
                paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees ==
                0
                  ? 0
                  : paymentInstruction.federalTaxes.actualTaxAmount /
                    paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees;
            }

            if (
              paymentInstruction.stateTaxes &&
              paymentInstructionResult.stateTaxes
            ) {
              paymentInstruction.stateTaxes.taxAuthority =
                paymentInstructionResult.stateTaxes.taxAuthority;
              paymentInstruction.stateTaxes.taxAuthorityStateCode =
                paymentInstructionResult.stateTaxes.taxAuthorityStateCode;
              paymentInstruction.stateTaxes.assessedTaxPercentage =
                paymentInstructionResult.stateTaxes.assessedTaxPercentage;
              paymentInstruction.stateTaxes.assessedTaxMinimumWitholdingAmount =
                paymentInstructionResult.stateTaxes.assessedTaxMinimumWitholdingAmount;
              paymentInstruction.stateTaxes.assessedTaxAllowsVoluntaryOptOut =
                paymentInstructionResult.stateTaxes.assessedTaxAllowsVoluntaryOptOut;
              paymentInstruction.stateTaxes.assessedStateDoesNotAllowTaxWithholding =
                paymentInstructionResult.stateTaxes.assessedStateDoesNotAllowTaxWithholding;
              // only overwrite actual tax amount when not already set or when actual tax amount
              // is smaller than assessed tax minimum withholding amount
              if (paymentInstructionResult.userExplicitlySetStateTax) {
                if (paymentInstruction.stateTaxes.requestedTaxPercentage) {
                  paymentInstruction.stateTaxes.actualTaxAmount =
                    paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees *
                    paymentInstruction.stateTaxes.requestedTaxPercentage;
                } else {
                  paymentInstruction.stateTaxes.actualTaxAmount =
                    paymentInstruction.stateTaxes.requestedTaxAmount;
                }
              } else if (
                !(
                  paymentInstruction.stateTaxes.actualTaxAmount != null &&
                  (paymentInstruction.stateTaxes.isVoluntarilyOptedOut ||
                    paymentInstruction.stateTaxes.actualTaxAmount >
                      paymentInstructionResult.stateTaxes
                        .assessedTaxMinimumWitholdingAmount)
                )
              ) {
                // A tax amount hasn't been set yet, so set to default amount
                if (
                  paymentInstructionResult.stateTaxes
                    .assessedTaxAllowsVoluntaryOptOut &&
                  paymentInstructionResult.stateTaxes
                    .assessedTaxDefaultsToOptedOut
                ) {
                  paymentInstruction.stateTaxes.actualTaxAmount = 0;
                  paymentInstruction.stateTaxes.isVoluntarilyOptedOut = true;
                } else {
                  paymentInstruction.stateTaxes.actualTaxAmount =
                    paymentInstructionResult.stateTaxes.assessedTaxMinimumWitholdingAmount;
                }
              }

              paymentInstruction.stateTaxes.actualTaxPercentage =
                paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees ==
                0
                  ? 0
                  : paymentInstruction.stateTaxes.actualTaxAmount /
                    paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees;
            }

            if (
              paymentInstruction.localTaxes &&
              paymentInstructionResult.localTaxes
            ) {
              paymentInstruction.localTaxes.taxAuthority =
                paymentInstructionResult.localTaxes.taxAuthority;
              paymentInstruction.localTaxes.taxAuthorityStateCode =
                paymentInstructionResult.localTaxes.taxAuthorityStateCode;
              paymentInstruction.localTaxes.assessedTaxPercentage =
                paymentInstructionResult.localTaxes.assessedTaxPercentage;
              paymentInstruction.localTaxes.assessedTaxMinimumWitholdingAmount =
                paymentInstructionResult.localTaxes.assessedTaxMinimumWitholdingAmount;
              paymentInstruction.localTaxes.assessedTaxAllowsVoluntaryOptOut =
                paymentInstructionResult.localTaxes.assessedTaxAllowsVoluntaryOptOut;
              paymentInstruction.localTaxes.actualTaxPercentage =
                paymentInstructionResult.localTaxes.assessedTaxPercentage;
              // only overwrite actual tax amount when not already set or when actual tax amount
              // is smaller than assessed tax minimum withholding amount
              paymentInstruction.localTaxes.actualTaxAmount =
                paymentInstruction.localTaxes.actualTaxAmount != null &&
                (paymentInstruction.localTaxes.isVoluntarilyOptedOut ||
                  paymentInstruction.localTaxes.actualTaxAmount >
                    paymentInstructionResult.localTaxes
                      .assessedTaxMinimumWitholdingAmount)
                  ? paymentInstruction.localTaxes.actualTaxAmount
                  : paymentInstructionResult.localTaxes
                      .assessedTaxMinimumWitholdingAmount;
              paymentInstruction.localTaxes.actualTaxPercentage =
                paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees ==
                0
                  ? 0
                  : paymentInstruction.localTaxes.actualTaxAmount /
                    paymentInstruction.taxableAmountIncludingDefaultingLoanLessDeductedFees;
            }

            paymentInstruction.orderLineFees =
              paymentInstructionResult.paymentInstructionFees;
          }

          if ($.isFunction(callback)) {
            callback(response.result, null);
          }
        } else {
          if ($.isFunction(callback)) {
            callback(null, response);
          }
        }
      }.bind(this)
    );
  }

  isDistributionGroupOne() {
    var distributionGroup = this.distributionGroup;
    if (["taxableSavingsAccount"].includes(distributionGroup)) {
      return true;
    }

    return false;
  }

  isDistributionGroupTwo() {
    var distributionGroup = this.distributionGroup;
    if (["uncashedCheck"].includes(distributionGroup)) {
      return true;
    }

    return false;
  }

  isDistributionGroupOneOrTwo() {
    var result = this.isDistributionGroupOne() || this.isDistributionGroupTwo();

    return result;
  }

  isDistributionGroupThree() {
    var distributionGroup = this.distributionGroup;
    if (
      [
        "retirementAccountFoundParticipantIra",
        "retirementAccountFoundSelfDirectedIra",
      ].includes(distributionGroup)
    ) {
      return true;
    }

    return false;
  }

  isDistributionGroupFour() {
    var distributionGroup = this.distributionGroup;
    if (["retirementAccountFoundOther"].includes(distributionGroup)) {
      return true;
    }

    return false;
  }

  isDistributionGroupThreeOrFour() {
    var result =
      this.isDistributionGroupThree() || this.isDistributionGroupFour();

    return result;
  }

  getNextLevelIraPaymentInstructions(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.id +
      "/my-next-level-ira-payment-instructions/";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) {
            callback(response.result, null);
          }
        } else {
          if ($.isFunction(callback)) {
            callback(null, response.message);
          }
        }
      }.bind(this)
    );
  }
}

export default OrderLine;
