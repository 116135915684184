<template>
  <v-app id="advisor-app">
    <pc-singleton-modals id="singleton-advisor-modals"></pc-singleton-modals>
    <pc-singleton-overlay id="singleton-advisor-overlay"></pc-singleton-overlay>
    <pc-singleton-snackbar
      id="singleton-advisor-snackbar"
    ></pc-singleton-snackbar>

    <div v-if="initialAuthenticationRequestComplete" class="fill-height">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import notifyPendo from "@/shared/lib/notifyPendo";

export default {
  components: {},

  data: () => ({
    initialAuthenticationRequestComplete: false,
  }),

  watch: {
    $route() {
      // Called whenever the route changes.
    },
  },

  methods: {
    // Called whenever the route changes
    routeChanged() {},
  },

  mounted: function () {
    // This Advisor App component is only mounted once per page load, so this is the area to ADVISOR site bootstrapping

    // Initalize with the progress overlay
    var options = {
      subtext: "Loading...",
      opacity: 0.001,
    };
    this.$overlay.show(options);

    // Refresh authenticated user.
    this.$store.dispatch(
      "refreshAuthenticatedUser",
      function (user) {
        if (user && user.isStaffMember()) {
          document.location = window.amplifyRuntimeConfig.get(
            "VUE_APP_ADMIN_BASE_URL"
          );
        } else if (user && user.isClient()) {
          document.location = window.amplifyRuntimeConfig.get(
            "VUE_APP_CLIENT_BASE_URL"
          );
        } else {
          // all routing for advisor user is handled by router
          this.$overlay.hide();
          this.initialAuthenticationRequestComplete = true;
          notifyPendo(user);
        }
      }.bind(this)
    );

    this.$cache.fetchAndCacheLookupData();
  },
};
</script>

<style lang="scss">
#advisor-app {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;

  .v-card__text {
    line-height: 18px !important;
    font-size: 14px !important;
  }
  @media (max-width: 960px) {
    body {
      width: max-content !important;
    }
    .pc-advisor-app-container {
      width: max-content !important;
    }
  }

  @media print {
    .pc-advisor-app-container {
      width: 100% !important;
    }

    .v-navigation-drawer {
      display: none;
    }

    .v-main {
      padding-left: 0 !important;
    }
  }

  // Vuetify v2.4 changed styling for nested grid items, causing spacing across the
  // app to be affected. A solution was found here that restores our previous spacing:
  // https://github.com/vuetifyjs/vuetify/issues/12848
  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .row + .row {
    margin-top: 0;
  }
}

html {
  overflow: auto;
}

.requiredField:before {
  content: " *";
  color: #d32f2f;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 15px;
}

.requiredFieldText .requiredField:before {
  vertical-align: top;
}

.requiredFieldText {
  vertical-align: text-top;
  font-size: 13px;
  color: #989898;
}
</style>
