<template>
  <v-text-field
    :id="id"
    v-model="text"
    :rules="required ? textRules.concat(printabilityRules) : printabilityRules"
    :type="type"
    :label="
      showLabel
        ? label + (required || hideOptionalText ? '' : ' (optional)')
        : ''
    "
    :placeholder="placeholder"
    :validate-on-blur="true"
    :disabled="disabled"
    :clearable="clearable"
    :dense="dense"
    :maxLength="maxLength"
    :errorMessages="errors"
    :color="$colors.penChecksTeal"
    :hint="hint"
    :persistent-hint="persistentHint"
    :append-icon="appendIcon"
    v-on="$listeners"
    @keyup.enter="$emit('enter', $event)"
    @change="$emit('change', text)"
    @click:clear="$emit('clear')"
    @blur="$emit('blur')"
    @focus="errors = null"
    ref="textfield"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    appendIcon: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    dense: {
      type: Boolean,
    },
    noPostOfficeBoxes: {
      type: Boolean,
    },
    printableCharsValidation: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
    },
    hideOptionalText: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
    },
    minLength: {
      type: Number,
    },
  },

  data: function () {
    return {
      text: this.value, // If rules get much more complex, replace :rules= ternary operators with a computed function that determines which rulesets to apply
      textRules: [
        (v) =>
          !!v ||
          "Please enter " +
            (this.label.toUpperCase() == this.label
              ? this.label
              : this.label.toLowerCase()),
        () => {
          if (!this.value || this.value == "" || !this.noPostOfficeBoxes)
            return true;

          // Looks for [BOXWORD][NUMBER IDENTIFIER #][DIGITS]
          // P.O.Box 123
          // POBox # 123
          // P/OBox. 123
          // OfficeBox Number 123
          // PostOfficeBox Num. 123
          // Box 123

          var poBox =
            /\b(P\s*\.?\/?\s*O|OFFICE|POSTOFFICE)?\s*\.?\s*BOX\s*\.?\s*(num(ber)?|#)?\s*\.?\s*\d+/i;

          if (this.value.match(poBox))
            return "Post office box is not allowed. Please enter a physical address.";

          return true;
        },
      ],
      printabilityRules: [
        (v) => {
          if (this.printableCharsValidation) {
            const PRINTABLE_CHARS_REGEX =
              /[^ !"#$%^&'()*+,\-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]_`abcdefghijklmnopqrstuvwxyz{|}~¡¢£¥¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+/;
            if (PRINTABLE_CHARS_REGEX.test(v)) {
              return "An invalid character was found in this field. Please check your entry and try again.";
            }
            return true;
          }
          return true;
        },
        () => {
          if (!this.value || this.value == "" || !this.minLength) return true;
          if (this.value.length < this.minLength)
            return (
              (this.label.toUpperCase() == this.label
                ? this.label
                : this.label.charAt(0).toUpperCase() +
                  this.label.slice(1).toLowerCase()) +
              ` needs a minimun of ${this.minLength} letters.`
            );
          return true;
        },
      ],
      errors: null,
    };
  },
  watch: {
    text(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      if (this.text != newValue) {
        this.text = newValue;
      }
    },
    errorMessages(errors) {
      this.errors = errors;
    },
  },

  mounted: function () {
    this.$emit("mounted");
  },
};
</script>

<style lang="scss"></style>
