<template>
  <div
    :class="
      isParticipantPortalField
        ? 'email-address-text-field is-participant-portal-field'
        : 'email-address-text-field is-general-field'
    "
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="email-address-text-field-header-style"
    >
      <span v-if="required && !isNextLevelField" class="error-text">*</span>{{ header }}
    </div>
    <v-text-field
      v-model="emailAddress"
      :rules="emailAddressRules"
      :label="
        isParticipantPortalField
          ? ''
          : label + (required || hideOptionalText ? '' : ' (optional)')
      "
      :placeholder="placeholder"
      validate-on-blur
      :error-messages="errors"
      :color="$colors.penChecksTeal"
      :dense="dense"
      :disabled="disabled"
      :clearable="clearable"
      :required="required"
      @keyup.enter="$emit('enter', $event)"
      @focus="inputFocused"
      :outlined="isParticipantPortalField"
      :style="`--custom-border-color: ${borderColor}`"      
    >
      <template v-if="isParticipantPortalField && !hideIcon" v-slot:prepend-inner>
        <pc-email-icon class="email-address-text-icon-style" />
      </template>
    </v-text-field>
  </div>
</template>
<script>
import PcEmailIcon from "@/shared/components/design-system/PcEmailIcon.vue";

export default {
  name: "PcEmailAddressTextFieldReskinned",

  components: {
    PcEmailIcon,
  },

  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: "Email Address",
    },
    placeholder: {
      type: String,
      default: "Email Address",
    },
    required: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    hideOptionalText: {
      type: Boolean,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    isNextLevelField: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    header: {
      type: String,
      default: "Email",
    },
    borderColor: {
      type: String,
      default: "#e9edf6",
    },    
  },

  data: function () {
    return {
      emailAddress: this.value,
      emailAddressRules: [
        (v) => (this.required ? !!v : true) || "Field cannot be left blank",
        (v) =>
          (!this.multiple && v ? /[^\s]+@[^\s]+\.[^\s]+/.test(v) : true) ||
          "Enter a valid email",
        (v) =>
          (this.multiple && v
            ? /([^\s]+@[^\s]+\.[^\s]+,?\s*)+/.test(v)
            : true) || "Enter valid email addresses",
      ],
      errors: null,
    };
  },

  watch: {
    emailAddress(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.emailAddress = newValue.trim();
    },
    errorMessages(errors) {
      this.errors = errors;
    },
  },

  methods: {
    inputFocused: function () {
      this.errors = null;
      this.$emit("focus");
    },
  },
};
</script>

<style lang="scss">
.email-address-text-field.is-participant-portal-field {
  .email-address-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input__prepend-inner {
    margin-top: 12px !important;

    .email-address-text-icon-style {
      color: $primary;
      margin-right: 4px;
    }
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: var(--custom-border-color) !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    position: relative;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
      position: absolute;
      top: 0px;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }
}
</style>
