import Vue from "vue";

Vue.config.productionTip = false;

// Plugins
import vuetify from "./shared/plugins/vuetify";
import i18n from "./shared/plugins/i18n";
import cookies from "vue-cookies";
import colors from "./shared/plugins/penchecks-colors";
import singletons from "./shared/plugins/singletons";
import urlHash from "./shared/plugins/url-hash";
import vueHelpers from "./shared/plugins/vue-helpers";
import defaultReusableComponents from "./shared/plugins/default-reusable-components";
import VueGtag from "vue-gtag";
import statusColorTheme from "./shared/plugins/status-color-theme";
import statusTooltips from "./shared/plugins/status-tooltips";
import cache from "./shared/plugins/cache";
import RuntimeConfiguration from "@/shared/runtime-configuration";
import { datadogLogs } from "@datadog/browser-logs";

Vue.use(cookies);
Vue.use(colors);
Vue.use(singletons);
Vue.use(urlHash);
Vue.use(vueHelpers);
Vue.use(defaultReusableComponents);
Vue.use(statusColorTheme);
Vue.use(statusTooltips);

// Directives
import "./shared/directives/click-outside-directive";

// Filters
import "./shared/filters/global-string-filters";
import "./shared/filters/global-date-filters";
import "./shared/filters/global-color-filters";
import "./shared/filters/global-number-filters";

import PcAdminApp from "./admin/PcAdminApp.vue";
import AdminRouter from "./admin/router";
import AdminStore from "./admin/store";

import PcAuthApp from "./auth/PcAuthApp.vue";
import AuthRouter from "./auth/router";
import AuthStore from "./auth/store";

import PcClientApp from "./client/PcClientApp.vue";
import ClientRouter from "./client/router";
import ClientStore from "./client/store";

import PcParticipantApp from "./participant/PcParticipantApp.vue";
import ParticipantRouter from "./participant/router";
import ParticipantStore from "./participant/store";

import PcNationalRegistryApp from "./national-registry/PcNationalRegistryApp.vue";
import NationalRegistryRouter from "./national-registry/router";
import NationalRegistryStore from "./national-registry/store";

import MyBenefitElectionApp from "./my-benefit-election/App.vue";
import MyBenefitElectionRouter from "./my-benefit-election/router";
import MyBenefitElectionStore from "./my-benefit-election/store";

import PcAdvisorApp from "./advisor/PcAdvisorApp.vue";
import AdvisorRouter from "./advisor/router";
import AdvisorStore from "./advisor/store";

import PcAccountHolderApp from "./nextlevel-account-holder/PcAccountHolderApp.vue";
import AccountHolderRouter from "./nextlevel-account-holder/router";
import AccountHolderStore from "./nextlevel-account-holder/store";

// Carbon Design System icons
import {
  CarbonIconsVue,
  Menu16,
  Menu20,
  Menu24,
  Menu32,
  Switcher16,
  Switcher20,
  Switcher24,
  Switcher32,
  OverflowMenuVertical16,
  OverflowMenuVertical20,
  OverflowMenuVertical24,
  OverflowMenuVertical32,
  OverflowMenuHorizontal16,
  OverflowMenuHorizontal20,
  OverflowMenuHorizontal24,
  OverflowMenuHorizontal32,
  Home16,
  Home20,
  Home24,
  Home32,
  Close16,
  Close20,
  Close24,
  Close32,
  CloseFilled16,
  CloseFilled20,
  CloseFilled24,
  CloseFilled32,
  CloseOutline16,
  CloseOutline20,
  CloseOutline24,
  CloseOutline32,
  Add16,
  Add20,
  Add24,
  Add32,
  AddAlt16,
  AddAlt20,
  AddAlt24,
  AddAlt32,
  Minimize16,
  Minimize20,
  Minimize24,
  Minimize32,
  Maximize16,
  Maximize20,
  Maximize24,
  Maximize32,
  ArrowDown16,
  ArrowDown20,
  ArrowDown24,
  ArrowDown32,
  ArrowDownLeft16,
  ArrowDownLeft20,
  ArrowDownLeft24,
  ArrowDownLeft32,
  ArrowDownRight16,
  ArrowDownRight20,
  ArrowDownRight24,
  ArrowDownRight32,
  ArrowLeft16,
  ArrowLeft20,
  ArrowLeft24,
  ArrowLeft32,
  ArrowRight16,
  ArrowRight20,
  ArrowRight24,
  ArrowRight32,
  ArrowShiftDown16,
  ArrowShiftDown20,
  ArrowShiftDown24,
  ArrowShiftDown32,
  ArrowUp16,
  ArrowUp20,
  ArrowUp24,
  ArrowUp32,
  ArrowUpLeft16,
  ArrowUpLeft20,
  ArrowUpLeft24,
  ArrowUpLeft32,
  ArrowUpRight16,
  ArrowUpRight20,
  ArrowUpRight24,
  ArrowUpRight32,
  ArrowsHorizontal16,
  ArrowsHorizontal20,
  ArrowsHorizontal24,
  ArrowsHorizontal32,
  ArrowsVertical16,
  ArrowsVertical20,
  ArrowsVertical24,
  ArrowsVertical32,
  ChevronDown16,
  ChevronDown20,
  ChevronDown24,
  ChevronDown32,
  ChevronDownGlyph,
  ChevronLeft16,
  ChevronLeft20,
  ChevronLeft24,
  ChevronLeft32,
  ChevronLeftGlyph,
  ChevronMini16,
  ChevronMini20,
  ChevronMini24,
  ChevronMini32,
  ChevronRight16,
  ChevronRight20,
  ChevronRight24,
  ChevronRight32,
  ChevronRightGlyph,
  ChevronSort16,
  ChevronSort20,
  ChevronSort24,
  ChevronSort32,
  ChevronSortDown16,
  ChevronSortDown20,
  ChevronSortDown24,
  ChevronSortDown32,
  ChevronSortUp16,
  ChevronSortUp20,
  ChevronSortUp24,
  ChevronSortUp32,
  ChevronUp16,
  ChevronUp20,
  ChevronUp24,
  ChevronUp32,
  ChevronUpGlyph,
  Checkbox16,
  Checkbox20,
  Checkbox24,
  Checkbox32,
  CheckboxChecked16,
  CheckboxChecked20,
  CheckboxChecked24,
  CheckboxChecked32,
  CheckboxCheckedFilled16,
  CheckboxCheckedFilled20,
  CheckboxCheckedFilled24,
  CheckboxCheckedFilled32,
  CheckboxIndeterminate16,
  CheckboxIndeterminate20,
  CheckboxIndeterminate24,
  CheckboxIndeterminate32,
  CheckboxIndeterminateFilled16,
  CheckboxIndeterminateFilled20,
  CheckboxIndeterminateFilled24,
  CheckboxIndeterminateFilled32,
  RadioButton16,
  RadioButton20,
  RadioButton24,
  RadioButton32,
  RadioButtonChecked16,
  RadioButtonChecked20,
  RadioButtonChecked24,
  RadioButtonChecked32,
  Export16,
  Export20,
  Export24,
  Export32,
  Download16,
  Download20,
  Download24,
  Download32,
  Folder16,
  Folder20,
  Folder24,
  Folder32,
  Email16,
  Email20,
  Email24,
  Email32,
  Phone16,
  Phone20,
  Phone24,
  Phone32,
  Launch16,
  Launch20,
  Launch24,
  Launch32,
  Password16,
  Password20,
  Password24,
  Password32,
  Unlocked16,
  Unlocked20,
  Unlocked24,
  Unlocked32,
  Locked16,
  Locked20,
  Locked24,
  Locked32,
  View16,
  View20,
  View24,
  View32,
  ViewOff16,
  ViewOff20,
  ViewOff24,
  ViewOff32,
  ViewFilled16,
  ViewFilled20,
  ViewFilled24,
  ViewFilled32,
  VerticalView32,
  ViewOffFilled16,
  ViewOffFilled20,
  ViewOffFilled24,
  ViewOffFilled32,
  Checkmark16,
  Checkmark20,
  Checkmark24,
  Checkmark32,
  CheckmarkFilled16,
  CheckmarkFilled20,
  CheckmarkFilled24,
  CheckmarkFilled32,
  CheckmarkFilledError16,
  CheckmarkFilledError20,
  CheckmarkFilledError24,
  CheckmarkFilledError32,
  CheckmarkFilledWarning16,
  CheckmarkFilledWarning20,
  CheckmarkFilledWarning24,
  CheckmarkFilledWarning32,
  CheckmarkOutline16,
  CheckmarkOutline20,
  CheckmarkOutline24,
  CheckmarkOutline32,
  CheckmarkOutlineError16,
  CheckmarkOutlineError20,
  CheckmarkOutlineError24,
  CheckmarkOutlineError32,
  CheckmarkOutlineWarning16,
  CheckmarkOutlineWarning20,
  CheckmarkOutlineWarning24,
  CheckmarkOutlineWarning32,
  CurrencyDollar16,
  CurrencyDollar20,
  CurrencyDollar24,
  CurrencyDollar32,
  Percentage16,
  Percentage20,
  Percentage24,
  Percentage32,
  Calculator16,
  Calculator20,
  Calculator24,
  Calculator32,
  Filter16,
  Filter20,
  Filter24,
  Filter32,
  FilterEdit16,
  FilterEdit20,
  FilterEdit24,
  FilterEdit32,
  FilterRemove16,
  FilterRemove20,
  FilterRemove24,
  FilterRemove32,
  Edit16,
  Edit20,
  Edit24,
  Edit32,
  FilterReset16,
  FilterReset20,
  FilterReset24,
  FilterReset32,
  ChooseItem16,
  ChooseItem20,
  ChooseItem24,
  ChooseItem32,
  Reset16,
  Reset20,
  Reset24,
  Reset32,
  Column16,
  Column20,
  Column24,
  Column32,
} from "@carbon/icons-vue";

Vue.use(CarbonIconsVue, {
  components: {
    Menu16,
    Menu20,
    Menu24,
    Menu32,
    Switcher16,
    Switcher20,
    Switcher24,
    Switcher32,
    OverflowMenuVertical16,
    OverflowMenuVertical20,
    OverflowMenuVertical24,
    OverflowMenuVertical32,
    OverflowMenuHorizontal16,
    OverflowMenuHorizontal20,
    OverflowMenuHorizontal24,
    OverflowMenuHorizontal32,
    Home16,
    Home20,
    Home24,
    Home32,
    Close16,
    Close20,
    Close24,
    Close32,
    CloseFilled16,
    CloseFilled20,
    CloseFilled24,
    CloseFilled32,
    CloseOutline16,
    CloseOutline20,
    CloseOutline24,
    CloseOutline32,
    Add16,
    Add20,
    Add24,
    Add32,
    AddAlt16,
    AddAlt20,
    AddAlt24,
    AddAlt32,
    Minimize16,
    Minimize20,
    Minimize24,
    Minimize32,
    Maximize16,
    Maximize20,
    Maximize24,
    Maximize32,
    ArrowDown16,
    ArrowDown20,
    ArrowDown24,
    ArrowDown32,
    ArrowDownLeft16,
    ArrowDownLeft20,
    ArrowDownLeft24,
    ArrowDownLeft32,
    ArrowDownRight16,
    ArrowDownRight20,
    ArrowDownRight24,
    ArrowDownRight32,
    ArrowLeft16,
    ArrowLeft20,
    ArrowLeft24,
    ArrowLeft32,
    ArrowRight16,
    ArrowRight20,
    ArrowRight24,
    ArrowRight32,
    ArrowShiftDown16,
    ArrowShiftDown20,
    ArrowShiftDown24,
    ArrowShiftDown32,
    ArrowUp16,
    ArrowUp20,
    ArrowUp24,
    ArrowUp32,
    ArrowUpLeft16,
    ArrowUpLeft20,
    ArrowUpLeft24,
    ArrowUpLeft32,
    ArrowUpRight16,
    ArrowUpRight20,
    ArrowUpRight24,
    ArrowUpRight32,
    ArrowsHorizontal16,
    ArrowsHorizontal20,
    ArrowsHorizontal24,
    ArrowsHorizontal32,
    ArrowsVertical16,
    ArrowsVertical20,
    ArrowsVertical24,
    ArrowsVertical32,
    ChevronDown16,
    ChevronDown20,
    ChevronDown24,
    ChevronDown32,
    ChevronDownGlyph,
    ChevronLeft16,
    ChevronLeft20,
    ChevronLeft24,
    ChevronLeft32,
    ChevronLeftGlyph,
    ChevronMini16,
    ChevronMini20,
    ChevronMini24,
    ChevronMini32,
    ChevronRight16,
    ChevronRight20,
    ChevronRight24,
    ChevronRight32,
    ChevronRightGlyph,
    ChevronSort16,
    ChevronSort20,
    ChevronSort24,
    ChevronSort32,
    ChevronSortDown16,
    ChevronSortDown20,
    ChevronSortDown24,
    ChevronSortDown32,
    ChevronSortUp16,
    ChevronSortUp20,
    ChevronSortUp24,
    ChevronSortUp32,
    ChevronUp16,
    ChevronUp20,
    ChevronUp24,
    ChevronUp32,
    ChevronUpGlyph,
    Checkbox16,
    Checkbox20,
    Checkbox24,
    Checkbox32,
    CheckboxChecked16,
    CheckboxChecked20,
    CheckboxChecked24,
    CheckboxChecked32,
    CheckboxCheckedFilled16,
    CheckboxCheckedFilled20,
    CheckboxCheckedFilled24,
    CheckboxCheckedFilled32,
    CheckboxIndeterminate16,
    CheckboxIndeterminate20,
    CheckboxIndeterminate24,
    CheckboxIndeterminate32,
    CheckboxIndeterminateFilled16,
    CheckboxIndeterminateFilled20,
    CheckboxIndeterminateFilled24,
    CheckboxIndeterminateFilled32,
    RadioButton16,
    RadioButton20,
    RadioButton24,
    RadioButton32,
    RadioButtonChecked16,
    RadioButtonChecked20,
    RadioButtonChecked24,
    RadioButtonChecked32,
    Export16,
    Export20,
    Export24,
    Export32,
    Download16,
    Download20,
    Download24,
    Download32,
    Folder16,
    Folder20,
    Folder24,
    Folder32,
    Email16,
    Email20,
    Email24,
    Email32,
    Phone16,
    Phone20,
    Phone24,
    Phone32,
    Launch16,
    Launch20,
    Launch24,
    Launch32,
    Password16,
    Password20,
    Password24,
    Password32,
    Unlocked16,
    Unlocked20,
    Unlocked24,
    Unlocked32,
    Locked16,
    Locked20,
    Locked24,
    Locked32,
    View16,
    View20,
    View24,
    View32,
    ViewOff16,
    ViewOff20,
    ViewOff24,
    ViewOff32,
    ViewFilled16,
    ViewFilled20,
    ViewFilled24,
    ViewFilled32,
    VerticalView32,
    ViewOffFilled16,
    ViewOffFilled20,
    ViewOffFilled24,
    ViewOffFilled32,
    Checkmark16,
    Checkmark20,
    Checkmark24,
    Checkmark32,
    CheckmarkFilled16,
    CheckmarkFilled20,
    CheckmarkFilled24,
    CheckmarkFilled32,
    CheckmarkFilledError16,
    CheckmarkFilledError20,
    CheckmarkFilledError24,
    CheckmarkFilledError32,
    CheckmarkFilledWarning16,
    CheckmarkFilledWarning20,
    CheckmarkFilledWarning24,
    CheckmarkFilledWarning32,
    CheckmarkOutline16,
    CheckmarkOutline20,
    CheckmarkOutline24,
    CheckmarkOutline32,
    CheckmarkOutlineError16,
    CheckmarkOutlineError20,
    CheckmarkOutlineError24,
    CheckmarkOutlineError32,
    CheckmarkOutlineWarning16,
    CheckmarkOutlineWarning20,
    CheckmarkOutlineWarning24,
    CheckmarkOutlineWarning32,
    CurrencyDollar16,
    CurrencyDollar20,
    CurrencyDollar24,
    CurrencyDollar32,
    Percentage16,
    Percentage20,
    Percentage24,
    Percentage32,
    Calculator16,
    Calculator20,
    Calculator24,
    Calculator32,
    Filter16,
    Filter20,
    Filter24,
    Filter32,
    FilterEdit16,
    FilterEdit20,
    FilterEdit24,
    FilterEdit32,
    FilterRemove16,
    FilterRemove20,
    FilterRemove24,
    FilterRemove32,
    Edit16,
    Edit20,
    Edit24,
    Edit32,
    FilterReset16,
    FilterReset20,
    FilterReset24,
    FilterReset32,
    ChooseItem16,
    ChooseItem20,
    ChooseItem24,
    ChooseItem32,
    Reset16,
    Reset20,
    Reset24,
    Reset32,
    Column16,
    Column20,
    Column24,
    Column32,
  },
});

// Our application contains no build time configuration. This allows us to build
// once and deploy anywhere. All stage specific configuration (e.g. the url with
// which to log in) is now fetched at runtime. The properties.json file is
// compiled to a static file as part of the nginx container start. During the
// nginx container start the file:
// Web\src\shared\runtime-configuration\properties.json is read and the
// properties defined there are substituted with the environment variables
// supplied to the container. For more details see
// nginx\process_vue_runtime_config.sh
const getRuntimeConfigurtionProperties = async () => {
  const runtimeConfigResponse = await fetch(
    "/runtime-configuration/properties.json"
  );
  return await runtimeConfigResponse.json();
};

getRuntimeConfigurtionProperties().then((runtimeConfigurationProperties) => {
  // Normally I'd like to see something like this in a vuex store, however
  // there are peices of the vue app that, when bootstrapping (before vuex is
  // instanciated), need some of the values in the runtime config. For that
  // reason I have populated the window (global) with the runtime config.
  window.amplifyRuntimeConfig = new RuntimeConfiguration(
    runtimeConfigurationProperties
  );

  const host = window.location.host;
  const requestProtocol = window.amplifyRuntimeConfig.get(
    "VUE_APP_REQUEST_PROTOCOL"
  );

  // Configure the application to publish errors to datadog. There is no value
  // publishing logs from our local development environments as developers have
  // direct access to the browser console.
  if (window.amplifyRuntimeConfig.get("VUE_APP_ENVIRONMENT") != "local") {
    datadogLogs.init({
      clientToken: "pub0cb678537161c308bb177c322fe6a10f",
      site: "datadoghq.com",
      service: "amplify-client",
      env: window.amplifyRuntimeConfig.get("VUE_APP_ENVIRONMENT"),
      version: window.amplifyRuntimeConfig.get("VUE_APP_BUILD_VERSION"),
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  }

  const isAdminDomain = host.match(/admin.*\.penchecks\.com/g) !== null;
  let isAuthDomain = host.match(/auth.*\.penchecks\.com/g) !== null;
  const isClientDomain = host.match(/amplify.*\.penchecks\.com/g) !== null;
  const isParticipantDomain = host.match(/my.*\.penchecks\.com/g) !== null;
  const isNationalRegistryDomain =
    host.match(/unclaimedretirementbenefits\.com/g) !== null;
  const isBenefitElectionDomain =
    host.match(/.*mybenefitelection.com/g) !== null;
  const isAdvisorDomain =
    host.match(/advisorportal.*\.penchecks\.com/g) !== null;
  const isAccountHolderDomain =
    host.match(/nextlevel.*\.penchecks\.com/g) !== null;

  if (host == "www.unclaimedretirementbenefits.com") {
    const pathName = window.location.pathname;
    window.location = `${requestProtocol}://unclaimedretirementbenefits.com${pathName}`;
    isAuthDomain = true; // Just so it doesn't error out
  }

  let app, store, router, googleAnalyticsTag;

  switch (true) {
    case isAdminDomain:
      app = PcAdminApp;
      store = AdminStore;
      router = AdminRouter;
      googleAnalyticsTag = "UA-154461583-9";
      break;
    case isAuthDomain:
      app = PcAuthApp;
      store = AuthStore;
      router = AuthRouter;
      googleAnalyticsTag = "UA-154461583-6";
      break;
    case isClientDomain:
      app = PcClientApp;
      store = ClientStore;
      router = ClientRouter;
      googleAnalyticsTag = "UA-154461583-8";
      break;
    case isParticipantDomain:
      app = PcParticipantApp;
      store = ParticipantStore;
      router = ParticipantRouter;
      googleAnalyticsTag = "UA-154461583-7";
      break;
    case isNationalRegistryDomain:
      app = PcNationalRegistryApp;
      store = NationalRegistryStore;
      router = NationalRegistryRouter;
      googleAnalyticsTag = "UA-154461583-5";
      break;
    case isBenefitElectionDomain:
      app = MyBenefitElectionApp;
      store = MyBenefitElectionStore;
      router = MyBenefitElectionRouter;
      break;
    case isAdvisorDomain:
      app = PcAdvisorApp;
      store = AdvisorStore;
      router = AdvisorRouter;
      // googleAnalyticsTag = "UA-154461583-8";
      break;
    case isAccountHolderDomain:
      app = PcAccountHolderApp;
      store = AccountHolderStore;
      router = AccountHolderRouter;
      break;
  }

  store.dispatch("fetchSystemProperties");

  if (googleAnalyticsTag) {
    Vue.use(VueGtag, {
      config: { id: googleAnalyticsTag },
    });
  }

  Vue.use(cache);

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(app),
  }).$mount("#app");
});
